import styled from "styled-components";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MydLogoHighlight } from "src/assets/svgs";

import Page1 from "src/assets/images/page1_mobile.png";
import Page2 from "src/assets/images/page2_mobile.png";
import Page3 from "src/assets/images/page3_mobile.png";
import Page4 from "src/assets/images/page4_mobile.png";
import Page5 from "src/assets/images/page5_mobile.png";
import Page6 from "src/assets/images/page6_mobile.png";
import Page7 from "src/assets/images/page7_mobile.png";
import Page8 from "src/assets/images/page8_mobile.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Preview() {
  return (
    <S.Container>
      <S.ScreenContainer>
        <span>마이디 서비스 미리보기</span>
        <Swiper
          centeredSlides
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          width={231}
        >
          <SwiperSlide>
            <S.Mockup src={Page1} alt="preview1" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page2} alt="preview2" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page3} alt="preview3" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page4} alt="preview4" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page5} alt="preview5" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page6} alt="preview6" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page7} alt="preview7" />
          </SwiperSlide>
          <SwiperSlide>
            <S.Mockup src={Page8} alt="preview8" />
          </SwiperSlide>
        </Swiper>
      </S.ScreenContainer>
      <S.TextContainer>
        <S.SubText>나의 데이터, 직접 거래하자!</S.SubText>

        <S.MainTextTop>
          일상에서 <MydLogoHighlight />를 모으다
        </S.MainTextTop>
        <S.MainText>이제는 마이디 하세요!</S.MainText>
      </S.TextContainer>
    </S.Container>
  );
}

export default Preview;

const S = {
  Container: styled.section`
    width: 100%;
    height: 809px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .swiper-wrapper {
      width: 231px;
    }
  `,
  ScreenContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 231px;
    margin-bottom: 40px;
    span {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.368px;
      margin-bottom: 6px;
    }
  `,
  Mockup: styled.img`
    width: 231px;
    height: 480px;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  SubText: styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.414px;
    white-space: nowrap;
    text-align: center;
  `,
  MainText: styled.p`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.644px;
    white-space: nowrap;
    text-align: center;
  `,
  MainTextTop: styled.p`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.644px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    white-space: nowrap;
    svg {
      margin: 0 2px 0 6px;
      align-self: flex-end;
    }
  `,
};
