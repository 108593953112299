import styled from "styled-components";

import { cls } from "src/theme/colors";
import { Appleogo, GoogleplayLogo } from "src/assets/svgs";
import OperateExampleMobile from "src/assets/images/operate_example_mobile.png";

function TransactionIntro() {
  return (
    <S.Container>
      <S.Box>
        <S.Content>
          <S.TextBox>
            <S.SubText>가장 안전한 데이터 거래를 위해</S.SubText>
            <S.MainText>
              마이데이터 거래 기반
              <br />
              데이터테크 플랫폼, <S.Highlight>마이디</S.Highlight>
            </S.MainText>
          </S.TextBox>
          <S.BtnBox>
            <S.AppDownloadBtn
              href="https://play.google.com/store/apps/details?id=io.snplab.myd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoogleplayLogo />
              Google Play
            </S.AppDownloadBtn>
            <S.AppDownloadBtn
              href="https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B4%EB%94%94/id1576612543"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Appleogo />
              App Store
            </S.AppDownloadBtn>
          </S.BtnBox>
        </S.Content>
        <S.Img src={OperateExampleMobile} alt="operate_example_mobile" />
      </S.Box>
    </S.Container>
  );
}

export default TransactionIntro;

const S = {
  Container: styled.section`
    width: 100%;
    height: 626px;
    background-color: ${cls.white};
    display: flex;
    position: relative;
  `,
  Box: styled.div`
    width: 100%;
    height: 100%;
    margin: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Img: styled.img`
    width: 360px;
    position: absolute;
    right: 0;
    z-index: 0;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 360px;
    height: 100%;
    z-index: 1;
  `,
  TextBox: styled.div``,
  SubText: styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.414px;
    white-space: nowrap;
  `,
  MainText: styled.p`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.644px;
    white-space: nowrap;
  `,
  Highlight: styled.span`
    color: ${cls.pink};
  `,
  BtnBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 26px;
    margin-bottom: 80px;
  `,
  AppDownloadBtn: styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 80px;
    border-radius: 40px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.18px;
    padding: 0;
    margin: 0;
    border: none;
    background: rgba(0, 0, 0, 0.8);
    margin-bottom: 10px;
    svg {
      margin-right: 8px;
    }
  `,
};
