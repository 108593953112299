import styled from "styled-components";
import BannerScroll from "./BannerScroll";

function PointUsage() {
  return (
    <S.Container>
      <S.MainText>
        데이터 거래 후 <br />
        현금처럼 사용 가능
      </S.MainText>
      <S.SubText>
        교환권부터 금액권까지 다양하게! <br />
        거래당 평균 1,500 포인트 획득 가능
      </S.SubText>
      <S.BrandContainer>
        <S.IconFlow>
          <BannerScroll seq="first" />
        </S.IconFlow>
      </S.BrandContainer>
    </S.Container>
  );
}

export default PointUsage;

const S = {
  Container: styled.section`
    background: rgba(252, 89, 108, 0.10000000149011612);
    width: 100%;
    height: 398px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  `,
  MainText: styled.p`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.552px;
    margin-top: 80px;
    margin-bottom: 4px;
  `,
  SubText: styled.p`
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.368px;
  `,
  BrandContainer: styled.div`
    width: 100%;
    min-height: 80px;
    margin-top: 40px;
    position: relative;
    display: flex;
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .arrow:first-child {
      left: -50px;
    }
    .arrow:last-child {
      right: -50px;
      transform: translateY(-50%) rotate(180deg);
    }
  `,
  IconFlow: styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
  `,
};
